/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { Var } from './CssCustomProperties';
import img6274 from './static/IMG_6274_thumb.jpg';

export const GalleryCard = () => {
    const thumbs = useRef([
        'IMG_6274_thumb.jpg',
        'IMG_6275_thumb.jpg',
        'IMG_6276_thumb.jpg',
        'IMG_6277_thumb.jpg',
        'IMG_6278_thumb.jpg',
        'IMG_6279_thumb.jpg',
        'IMG_6280_thumb.jpg',
        'IMG_6281_thumb.jpg',
        'IMG_6282_thumb.jpg',
        'IMG_6283_thumb.jpg',
        'IMG_6284_thumb.jpg',
        'IMG_6285_thumb.jpg',
        'IMG_6286_thumb.jpg',
        'IMG_6287_thumb.jpg',
        'IMG_6288_thumb.jpg',
        'IMG_6289_thumb.jpg',
        'IMG_6290_thumb.jpg',
    ]);

    const [currentThumb, setCurrentThumb] = useState(0);
    const [imageSrc, setImageSrc] = useState<string>(img6274);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentThumb((previous) => (previous + 1 > thumbs.current.length - 1 ? 0 : previous + 1));
        }, 4000);
        return () => {
            clearTimeout(timer);
        };
    });

    useEffect(() => {
        const importImage = async () => {
            const imageSrc = await import(`./static/${thumbs.current[currentThumb]}`);
            console.log(thumbs.current[currentThumb], imageSrc.default);
            setImageSrc(imageSrc.default);
        };
        importImage();
    }, [currentThumb]);

    return (
        <div
            css={{
                display: 'flex',
                justifyContent: 'center',
                color: Var.PrimaryColor,
                backgroundColor: 'rgb(83, 101, 87)',
                boxShadow: '0px 0px 10px 0px rgba(18, 11, 18, 0.75)',
                textDecoration: 'none',
                '> * + *': {
                    marginTop: '1rem',
                },
                ':visited': {
                    color: Var.PrimaryColor,
                    textDecoration: 'none',
                },
            }}
        >
            <img
                //srcSet={`${banner_1200} 1960w, ${banner_960} 960w, ${banner_480} 480w`}
                src={imageSrc}
                alt={'Misty Mountain Potters Gallery'}
                css={{}}
            />
        </div>
    );
};
